<template>
  <div>
    <b-modal
      id="modal-checkin-dr"
      size="md"
      centered
      :no-close-on-backdrop="true"
      ok-only
      :ok-title="$t('golf_common_confirm')"
      :cancel-title="$t('golf_common_back')"
      ok-variant="primary"
      @ok="checkIn_ICID01"
      v-model="isOpen"
    >
      <template #modal-header="{ close }">
        <h4 class="mb-0 text-primary">
          {{ $t('golf_common_confirm_checkin') }}
        </h4>
        <ez-icon
          icon="ezGolf-icon-x"
          class="cursor-pointer"
          size="24"
          color="#114A9F"
          @click="close"
        />
      </template>
      <b-card-body>
        <b-row>
          <b-form-checkbox
              class="content-body-modal"
              v-model="isCheckAll"
              style="margin-right: 4px"
              @change="checkAll(isCheckAll)"
            />
            <span>In tất cả BDC</span>
        </b-row>
        <div
          v-for="(item, index) in items"
          :key="index"
          class="checkin-body mb-1"
        >
        <b-row>
            <h5>
            {{ convertUTC(item.OpenDate, 'DD/MM/YYYY HH:mm') }}
            <template
              v-for="course in item.BookingDrDetailCourse"
              v-if="course.CourseName"
            >
              {{ course.CourseName }}
            </template>
          </h5>
          </b-row>
          <b-row>
            <b-col md="6" class="d-flex">
              <b-form-checkbox
                  class="m-auto"
                  v-model="item.CheckPrint"
                />
              <b-media
                no-body
                class="user-info"
              >
                <b-media-aside
                  class="media-left"
                  style="margin-right: 10px;"
                >
                  <UserAvatar
                    size="24"
                    :user="item"
                  />
                </b-media-aside>
                <b-media-body class="text-truncate">
                  <h6 class="text-truncate mb-0">
                    {{ item.Member.FullName }}
                  </h6>
                </b-media-body>
              </b-media>
            </b-col>

            <b-col md="3">
              <b-form-group>
                <b-form-input
                  v-model="item.BagtagCode"
                  maxlength="30"
                  placeholder="Bagtag code"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <b-form-input
                  :autofocus="isOpen"
                  v-model="item.LockerCode"
                  maxlength="30"
                  placeholder="Locker code"
                />
              </b-form-group>
            </b-col>

          </b-row>
        </div>
        <div>
          <b-form-group>
            <b-form-checkbox v-model="sendMailCheckin">
              <p class="mb-0">
                {{ $t('golf_common_send_email_to_customer') }}
              </p>
            </b-form-checkbox>
          </b-form-group>
        </div>
      </b-card-body>
    </b-modal>
    <ShortKey
      :shortcuts="ShortCutKey"
      :isActive="isOpen"
      :allChildComponentClosed="allChildComponentClosed"
      @triggered="onTriggeredEventHandler"
    />
  </div>
</template>

<script>
import { bookingDrivingRange } from '@/api/booking-driving-range'
import ShortKey from '@/views/modules/ultils/ShortKey'
import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S} from '@/api/constant.js'
import UserAvatar from '@/views/modules/booking/UserAvatar'

export default {
  components: {
    UserAvatar,
    ShortKey
  },
  props: [
    'items',
  ],
  data() {
    return {
      sendMailCheckin: false,
      isOpen: false,
      ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F,S],
      allChildComponentClosed: [],
      isCheckAll: true
    }
  },
  watch: {
    isOpen(val) {
      this.$emit('is-open', {ComponentId: "modal-checkin-dr",IsActive:val});
    }
  },
  methods: {
    async checkIn_ICID01() {
      const BookingDrDetail = []
      const listPrintBookingDrDetail = []
      this.items.forEach(x => {
        if(x.CheckPrint) {
          listPrintBookingDrDetail.push(x.Id)
        }
        BookingDrDetail.push({
          Id: x.Id,
          BagtagCode: x.BagtagCode,
          LockerCode: x.LockerCode,
        })
      })
      const data = {
        SendEmail: this.sendMailCheckin,
        BookingDrDetail,
      }

      const response = await bookingDrivingRange.api_ICID01(data)

      if (response.Status == 200) {
        this.$emit('event', { type: 'after-checkin', data: listPrintBookingDrDetail})
      }
    },
    onTriggeredEventHandler(payload) {    
      // if (payload.key === F4) {
      //   setTimeout( () => {
      //     this.isOpen = false
      //   }, 500)
      // } 
      if(payload.evt.ctrlKey && payload.key === S) {
        this.checkIn_ICID01()
        setTimeout( () => {
          this.isOpen = false
        }, 500)
      }
    },
    checkAll(data) {
      // console.log(data)
      // console.log(this.items)
      this.items.forEach(x => {
        x.CheckPrint = data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
#modal-checkin-dr {
  .user-info {
    border: 1px solid #cdcdcd;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }

}
:v-deep{
    .modal-content {
      background: #FFFFFF;
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      width: 900px;
    }
    .b-table-sticky-header {
      overflow-y: auto !important;
      max-height: 85% !important;
    }
  }
</style>
